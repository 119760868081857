// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Libre+Baskerville';
    src: url('./fonts/LibreBaskerville-Regular.ttf') format('truetype');
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
}

body {
    margin: 0px;
}

$color-primary: #0C173C;
$color-secondary: #768A76;
$color-info: rgb(231, 240, 255);
$color-title: rgba(78, 78, 78, 1);
$color-background-light: #f3f8fbff;

@mixin rft_font {
    font-family: Montserrat, Arial, Verdana, sans-serif;
}

@mixin rft_logo_font {
    font-family: 'Libre+Baskerville', Arial, Verdana, sans-serif;
    color: $color-title;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.1em;
    text-align: center;
}

.rft_site {
    margin: 0;
    @include rft_font();
    font-size: 16px;
    color: #000;
    text-align: left;
    background-color: #fff;
}

.rft_site p {
    letter-spacing: .1em;
    line-height: 1.5;
}

.rft_header_super {
    background-color: #d5e4ed;
    width: 100%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rft_header_super a {
    display: inline-block;
    text-decoration: none;
    color: #0c173c;
    font-size: 0.8em;
    margin: 0 40px;
}

.rft_header_super a.selected {
    display: inline-block;
    padding: 4px 14px;
    text-decoration: none;
    background-color: #0c173ccc;
    border-radius: 14px;
    color: white;
}

.rft_header_outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff !important;
    z-index: 2;
    position: sticky;
    top: 0;
    box-shadow: 0 0px 6px 4px gray;
}

.rft_header_inner {
    width: 100%;
    margin: 0 0 24px 0px;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rft_header_left>p {
    font-family: 'Libre+Baskerville', Arial, Verdana, sans-serif;
    font-size: 16px;
    font-style: italic;
    color: rgba(78, 78, 78, 1);
    margin: 0px;
}

.rft_header_center {
    border-left: solid 1px rgba(4, 4, 4, 0.2);
    border-right: solid 1px rgba(4, 4, 4, 0.2);
    padding: 6px 0px;
}

.rft_header_title {
    @include rft_logo_font();
    margin: 0;
}

.rft_navigation {
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-bottom: 1px solid black;
}

.rft_navigation>a {
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.2em;
}

.rft_nav_item {
    flex: 1;
    text-align: center;
}

.rft_nav_item {
    border-bottom: 1px solid transparent;
}

.rft_nav_item:hover {
    border-bottom: 1px solid $color-primary;
}

.rft_divider {
    width: 100%;
    background-color: rgba(4, 4, 4, 0.2);
    height: 1px;
}

.rft_button {
    background-color: $color-primary;
    border-color: $color-primary;
    color: white;
    min-width: 200px;
}

.rft_footer {
    padding: 25px 0;
    text-align: center;
    border-top: 1px solid #ccc;
    min-height: 200px;
}

.rft_footer_inner {
    width: 100%;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
}

.rft_banner_text {
    font-size: 24px !important;
    font-weight: 700 !important;
    color: $color-primary;
    text-align: 'center';
}

.rft_footer_inner h1 {
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 20px;
    color: white;
}

.rft_footer_inner p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.1em;
    color: white;
}

.rft_mobile_hmenu_btn {
    position: absolute;
    left: 24px;
    top: 0;
}

.rft_comp_hero_image {
    width: 100%;
    height: 360px;
    max-height: 360px;
    display: flex;
    flex-direction: row;
}

.rft_hero_max {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.rft_comp_hero_image_text_alt {
    display: flex;
    flex-direction: column;
    width: 55%;
    margin-left: auto;
}

.rft_comp_hero_text_block {
    display: flex;
    flex-direction: column;
    font-family: Lato, sans-serif;
    margin: auto;
}

.rft_comp_hero_text_lead {
    font-size: 44px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: 62px;
    margin: 8px 0;
}

.rft_comp_hero_text_secondary {
    font-size: 1.9rem;
    color: #c58138;
    font-weight: 400;
    text-align: center;
}

.rft_home_stripe {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: row;
    background-color: $color-secondary;
}

.rft_home_stripe_reverse {
    flex-direction: row-reverse;
    background-color: $color-primary;
}

.rft_home_stripe>div {
    flex: 1 1 50%;
}

.rft_home_stripe_image {
    height: 450px;
    min-height: 250px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.rft_home_stripe .rft_home_stripe_image {
    margin-right: 24px;
}

.rft_home_stripe_reverse .rft_home_stripe_image {
    margin-right: 0px !important;
    margin-left: 24px;
}

.rft_home_learn_more {
    height: 450px;
    text-align: center;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: left;
    flex-wrap: wrap;
}

.rft_home_learn_more_paper {
    max-height: 225px;
    padding: 48px;
    width: 100%;
    box-shadow: -10px 10px 5px 2px rgba(0, 0, 0, 0.4) !important;
    //box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.54),0px 4px 18px 3px rgba(0,0,0,0.12) !important;
    margin-left: -60px;
    margin-right: 60px;
}

.rft_home_stripe_reverse .rft_home_learn_more_paper {
    margin-left: 60px;
    margin-right: -60px;
    box-shadow: 10px 10px 5px 2px rgba(0, 0, 0, 0.4) !important;
}

.rft_home_learn_more_inner {
    text-align: left;
    max-width: 600px;
    margin-left: 0px;
    margin-right: auto;
}

.rft_background_primary {
    background-color: $color-primary !important;
}

.rft_background_secondary {
    background-color: $color-secondary !important;
}

.rft_background_info {
    background-color: $color-info !important;
    color: $color-primary !important;
}

.rft_background_light {
    background-color: $color-background-light !important;
    color: $color-primary !important;
}

.rft_content_stripe {
    padding: 24px 0;
}

.rft_content_stripe_grey {
    padding: 24px 0;
    background-color: rgba(242, 242, 242, 1);
}

.rft_content {
    align-content: flex-start;
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
}

.rft_content_card {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 12px 24px;
    text-align: center;
    position: relative;
}

.rft_content_header {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1.2;
    color: $color-primary;
    text-align: center;
    margin: 0;
}

.rft_content_img {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    object-position: 50% 50%;
}

.rft_content_banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
}

.rft_content_card_body {
    text-align: left;
    line-height: 1.5;
}

.rft_content_card_button {
    position: absolute;
    bottom: -30px;
}

.rft_why_choose {
    display: flex;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1.2;
    color: $color-primary;
    margin: 12px;
}

.rft_why_choose img {
    height: 35px;
}

.rft_why_choose p {
    margin: 0;
    margin-left: 12px;
    align-self: center;
}

.rft_product_section_header {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1.2;
    color: $color-primary;
    text-align: left;
    margin: 0;
}

.rft_section_header {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1.2;
    color: $color-primary;
    text-align: left;
    margin: 0;
}

.rft_card_mobile {
    display: none;
    margin-bottom: 48px;
}

.rft_content_stripe::after {
    display: block;
    margin-top: 90%;
}

.rft_overlay_card {
    width: 350px;
    position: absolute;
}

.rft_hero_container {
    width: 100%;
    justify-content: center;
    align-content: stretch;
    display: block;
}

.rft_hero_container>div {
    max-height: 400px;
}

.rft_hero_blank_space {
    height: 400px;
    background-color: $color-secondary;
    text-align: center;
    border-right: 1px solid #CCC;
    position: relative;
}

.rft_hero_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.rft_hero_text_overlay {
    display: block;
}

.rft_hero_title {
    color: white;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1 !important;
    margin-bottom: 12px;
}

.rft_hero_subtitle {
    color: white;
    // text-transform: uppercase;
    line-height: 1.75;
}

.rft_hero_svg {
    position: absolute;
    top: 0;
    left: 0;
    margin-right: -25px;
}

.rft_hero_overlay {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 100px;

}

.rft_hero_overlay_left_spacer {
    flex: 3 2 0;
    min-width: 24px;
}

.rft_hero_text {
    flex: 0 0 350px;
}

.rft_hero_overlay_right_spacer {
    flex: 0 0 150px;
}

.rft_map_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 650px;
    min-height: 250px;
}

.rft_map_wrapper iframe {
    position: relative;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rft_page_content {
    padding: 48px;
}

.rft_contact_input {
    width: 100%;
    min-height: 40px;
    background-color: #F2F2F2;
    border: none;
    padding: 5px 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: uppercase;
    color: $color-primary;
    font-weight: 600;
    @include rft_font();
}

.rft_product_section_container {
    text-align: left;
}

.rft_product_section_container {
    text-align: left;
}

.rft_product_section_container h1 {
    color: $color-primary;
}

.rft_flexbox {
    display: flex;
    margin: 24px;
    gap: 2rem;
    flex-wrap: wrap;
}

.rft_faq_quick_links {
    background-color: $color-info;
    padding: 24px;

}

.rft_faq_section h2 {
    color: $color-primary;
    letter-spacing: .1em;
    font-size: 30px;
}

.rft_faq_question {
    color: $color-primary;
    font-weight: bold !important;
    letter-spacing: .1em;
    padding: 6px 0;
}

.rft_faq_answer {
    color: black;
    padding-left: 16px;
    padding-bottom: 16px;
}

.rft_faq_answer>p {
    margin-top: 0px;
}

.rft_grid_350 {
    display: grid;
    gap: 2rem;
    margin: auto;
    max-width: 1200px;
    justify-content: center;
    padding: 2rem 0;
    grid-template-columns: repeat(auto-fit, 350px);
}

.rft_product_square {
    width: 350px;
}

.rft_product_square p {
    font-size: 15px;
}

.rft_product_square_image {
    background-color: $color-info;
    text-align: center;
    padding: 24px;
}

.rft_product_square_image img {
    width: 252px;
    height: auto;
    max-height: 300px;
}

.rft_stack {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-items: stretch;
    margin-left: auto;
    margin-right: auto;
}

.rft_stack div:last-child {
    align-content: flex-end;
    align-self: flex-end;
    flex: 1 0 auto;
}

.rft_stack h2 {
    color: $color-primary;
    margin: 12px 0 0px;
    letter-spacing: .1em;
}

.rft_stack a {
    font-weight: 700;
    justify-self: flex-end;
    align-self: flex-end;
    flex: 1 0 auto;
    letter-spacing: .1em;
    font-size: 1.1em;
    text-transform: none;
}

.rft_document_section_header {
    letter-spacing: .1em;
    color: $color-primary;
    font-size: 1.5em;
}

.rft_document_link {
    font-size: .8em !important;
    font-weight: bold !important;
    text-transform: none !important;
}

.rft_coverage_table {
    margin-top: 24px;
}

.rft_coverage_table th {
    font-weight: 700;
    font-size: 1em;
    letter-spacing: .1em;
    text-align: left;
    text-transform: uppercase;
    color: white;
    background-color: $color-primary;
}

.rft_coverage_table td {
    font-size: 1em;
    letter-spacing: .1em;
    border: none;
    padding: 12px;
}

.rft_coverage_table tr:nth-child(even) {
    background-color: $color-info;
}

.rft_coverage_calc {
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 18px;
}

.rft_coverage_calc_control .MuiInputBase-formControl::before {
    border: none !important;
}

.rft_coverage_calc_control:first-child .MuiInputLabel-shrink {
    transform: translate(14px, 8px) scale(0.75) !important;
}

.rft_coverage_calc_control div {
    background-color: $color-info !important;
}

.rft_coverage_calc_control {
    width: 100%;
}

.rft_coverage_calc_control select {
    font-weight: bold;
    letter-spacing: .1em;
}

.rft_coverage_calc_control input {
    font-weight: bold;
    letter-spacing: .1em;
}

.rft_coverage_calc_results {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 60px;
    justify-content: space-between;
    align-items: stretch;
    text-align: center;
    color: $color-title;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: .1em;
    line-height: 1.5;

}

.rft_img_about_circle {
    width: 325px;
    height: 325px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.rft_img_about_circle img {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.rft_img_text_stack>div {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.rft_img_text_stack img {
    width: 100%;
}

.rft_img_text_stack h3 {
    color: $color-primary;
}

.rft_img_text_stack h4 {
    color: $color-primary;
    text-transform: uppercase !important;
    letter-spacing: .1em !important;
}

.rft_img_text_stack p {
    letter-spacing: 0em;
    line-height: 1.5;
}


.rft_img_about_circle {
    clip-path: circle();
    max-height: 325px;
}

.rft_cat_card_container {
    display: grid;
    gap: 2rem;
    margin: auto;
    max-width: 1200px;
    align-items: center;
    padding: 2rem 0;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.rft_cat_card {
    display: block;
    border-radius: 12px !important;
    width: 350px;
    height: 400px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.rft_cat_card div {
    padding: 0 18px;
}

.rft_cat_card img {
    height: 150px;
    width: 100%;
    object-position: center center;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
}

.rft_cat_card h3 {
    color: $color-primary;
    text-transform: uppercase !important;
    letter-spacing: .1em !important;
}

.rft_cat_card p {
    letter-spacing: .0em !important;
    line-height: 1.25;
}

.rft_cat_card a {
    font-weight: 700;
    position: absolute;
    right: 24px;
    bottom: 12px;
}

.rft_content_tile {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.rft_content_tile p {
    margin-bottom: 0;
}

.rft_content_tile_color_block {
    background-color: $color-primary;
    color: white;
    padding: 40px;
    flex: 1 1 35%;
    border-radius: 12px 0 0 12px;
}

.rft_content_tile_color_block h2 {
    margin: 0;
}

.rft_content_tile_color_block a {
    color: white;
}

.rft_content_tile_img {
    flex: 1 1 65%;
    background-position: center center;
    background-size: cover;
    border-radius: 0 12px 12px 0;
    margin-left: -1px;
    position: relative;
}

.rft_content_tile_chevron {
    margin-left: -1px;
    background-color: $color-primary;
    clip-path: polygon(0 0, 15% 50%, 0 100%);
    height: 100%;
}

.rft_content_tile_chevron2 {
    content: "";
    opacity: .3;
    clip-path: polygon(0 0, 30px 0, calc(15% + 30px) 50%, 30px 100%, 0 100%, 10% 50%, 0 0);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.rft_content_tile.reverse {
    flex-direction: row-reverse !important;
}

.rft_content_tile_color_block.reverse {
    border-radius: 0 12px 12px 0;
}

.rft_content_tile_img.reverse {
    border-radius: 12px 0 0 12px;
}

.rft_content_tile_chevron.reverse {
    margin-right: -1px;
    background-color: $color-primary;
    clip-path: polygon(100% 0, 85% 50%, 100% 100%);
    height: 100%;
}

.rft_content_tile_chevron2.reverse {
    content: "";
    opacity: .3;
    clip-path: polygon(100% 0, calc(100% - 30px) 0, calc(85% - 30px) 50%, calc(100% - 30px) 100%, 100% 100%, 85% 50%, 100% 100%);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.rft_sport_stack {
    text-align: center;
}

.rft_stain_lineup {
    margin-bottom: 48px;
    display: flex;
    align-content: stretch;
}

.rft_img_center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

.rft_product_section_container li {
    line-height: 1.5;
}

.rft_video_title {
    font-weight: 600 !important;
    letter-spacing: 0em !important;
    color: #333;
}

@media (min-width: 900px) {
    .rft_mobile_hmenu_btn {
        display: none;
    }

    .rft_header_links {
        margin-left: 100px;
    }

    .rft_mobile_only {
        display: none;
    }

    .rft_grid_350 {
        justify-content: flex-start;
    }
}

@media (max-width: 899px) {

    .rft_mobile_hmenu_btn {
        position: absolute;
        left: 24px;
        top: 24px;
    }

    .rft_content_tile,
    .rft_content_tile.reverse {
        flex-direction: column !important;
    }

    .rft_content_tile_color_block,
    .rft_content_tile_color_block.reverse {
        flex: 1 1 50%;
        border-radius: 12px 12px 0 0;
    }

    .rft_content_tile_img,
    .rft_content_tile_img.reverse {
        flex: 1 1 50%;
        border-radius: 0 0 12px 12px;
        min-height: 250px;
    }

    .rft_content_tile_chevron,
    .rft_content_tile_chevron.reverse {
        background-color: $color-primary;
        clip-path: polygon(0 -1px, 100% -1px, 50% 20%);
        width: 100%;
        height: 250px;
    }

    .rft_content_tile_chevron2,
    .rft_content_tile_chevron2.reverse {
        content: "";
        opacity: .3;
        clip-path: polygon(0 -1px, 0 30px, 50% calc(20% + 30px), 100% 30px, 100% -1px, 50% 20%, 0 0);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }


    .rft_hide_mobile {
        display: none;
    }

    .rft_home_stripe {
        flex-direction: column-reverse !important;
    }

    .rft_home_stripe_reverse {
        flex-direction: column-reverse !important;
        ;
    }

    .rft_home_stripe .rft_home_stripe_image {
        margin: 0 !important;
        height: 450px;
    }

    .rft_home_learn_more {
        text-align: center;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: left;
        flex-wrap: wrap;
    }

    .rft_home_learn_more_paper {
        max-height: 100%;
        padding: 48px;
        margin: 0px !important;
    }

    .rft_img_text_stack {
        max-width: 350px;
    }

    .rft_product_section_header {
        display: none;
    }

    .rft_stain_lineup {
        display: none !important;
    }

    .rft_document_link {
        display: block;
    }

    .rft_header_center {
        border-left: none;
        border-right: none;
        padding-top: 24px;
    }

    .rft_overlay_card {
        display: none;
    }

    .rft_card_mobile {
        display: block;
    }

    .rft_content_img {
        max-height: 250px;
        object-fit: cover;
        object-position: 50% 50%;
    }

    .rft_hero_blank_space {
        height: 100% !important;
        background-color: $color-secondary;
    }

    .rft_hero_overlay {
        margin: 0px;
        padding: 0 24px;
    }

    .rft_hero_overlay_left_spacer {
        display: none;
    }

    .rft_hero_overlay_right_spacer {
        display: none;
    }

    .rft_hero_text {
        flex: 1 0 0px;
    }

    .rft_hero_title {
        width: 100% !important;
    }

    .rft_hero_svg {
        display: none;
    }

    .rft_product_section_container {
        text-align: left !important;
        padding: 0 24px !important;
    }
}

@media (max-width: 599px) {

    .rft_header_inner {
        margin: 8px 0;
    }

    .rft_header_title {
        font-family: 'Libre+Baskerville', Arial, Verdana, sans-serif;
        color: $color-title;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 0.1em;
        text-align: center;
    }

    .rft_header_center {
        padding-top: 0;
    }

    .rft_mobile_hmenu_btn {
        position: absolute;
        left: 24px;
        top: 0px;
    }
}